import { Box, useMediaQuery, useTheme } from "@mui/material";
import { FC, useState } from "react";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Slider.css"; // We'll create this file for custom styling

type SliderProps = {
  images: string[];
};

export const Slider: FC<SliderProps> = ({ images }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loadedImages, setLoadedImages] = useState<Set<string>>(new Set());
  
  // Handle image load to detect small images
  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const img = event.currentTarget;
    const src = img.src;
    
    // Add to loaded images set
    setLoadedImages(prev => new Set(prev).add(src));
    
    // Check if image is small and add class if needed
    if (img.naturalWidth < 600 || img.naturalHeight < 400) {
      img.classList.add('small-image');
    }
  };

  const galleryItems = images.map((image) => ({
    original: image,
    thumbnail: image,
    originalAlt: "Gallery item",
    thumbnailAlt: "Thumbnail"
  }));
  
  return (
    <Box
      width={isSmallScreen ? "100%" : 790}
      minHeight={isSmallScreen ? 510 : "100%"}
      sx={{
        borderRadius: "10px",
        position: "relative",
        border: "1px solid #eee",
        overflow: "hidden",
      }}
      className="slider-container"
    >
      <ImageGallery
        items={images.map((i) => ({ original: i, thumbnail: i }))}
        showThumbnails
        showPlayButton={false}
        thumbnailPosition={isSmallScreen ? "bottom" : "right"}

        lazyLoad
        additionalClass="custom-image-gallery"
        renderItem={(item) => (
          <div className="image-container">
            <img
              src={item.original}
              alt={item.originalAlt}
              className="gallery-image"
              onLoad={handleImageLoad}
            />
          </div>
        )}
        renderThumbInner={(item) => (
          <div className="thumbnail-container">
            <img
              src={item.thumbnail}
              alt={item.thumbnailAlt}
              className="thumbnail-image"
            />
          </div>
        )}
      />
    </Box>
  );
};
