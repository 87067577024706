import { FC, useEffect, useState } from "react";
import { Car, CarOptions } from "../hooks/useCarSwitch";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Slider } from "./Slider";

type SelectedCarContentProps = {
  selectedCar: Car;
};

export const SelectedCarContent: FC<SelectedCarContentProps> = ({
  selectedCar,
}) => {
  const [selectedOption, setSelectedOption] = useState<CarOptions>();

  useEffect(() => {
    setSelectedOption(selectedCar.options[0]);
  }, [selectedCar.options]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(1380));

  const year = (
    <Stack
      sx={{
        width: 140,
        alignSelf: "center",
        height: 380,
        px: 2,
      }}
    >
      <Typography
        component={Box}
        mt={5}
        sx={(theme) => ({
          display: "flex",
          height: 140,
          color: "#D2D0D0",
          fontWeight: 700,
          width: "100%",

          transform: "rotate(90deg)",
          fontSize: 150,
          lineHeight: 1,
          fontStyle: "normal",

          [theme.breakpoints.down(1380)]: {
            fontSize: 130,
          },
          textShadow:
            "-1px 0 #ACACAC, 0 1px #ACACAC, 1px 0 #ACACAC, 0 -1px #ACACAC",
        })}
      >
        {selectedOption?.year}
      </Typography>
    </Stack>
  );
  return (
    <Stack
      px={3}

      direction="row"
      justifyContent="space-between"
      alignItems={isSmallScreen ? "inherit" : "center"}
      sx={(theme) => ({
        [theme.breakpoints.down(1380)]: {
          gap: 4,
          flexDirection: "column",
        },
      })}
    >
      <Stack justifyContent="space-between" gap={2}>
        <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
          <Typography variant="h1">{selectedCar.label}</Typography>
          {isSmallScreen ? (
            <Typography variant="h1">{selectedOption?.year}</Typography>
          ) : null}
          {selectedCar.options.map((o) => (
            <Box
              key={o.description}
              onClick={() => setSelectedOption(o)}
              sx={{
                borderRadius: "50%",
                backgroundColor: o.color,
                border:
                  selectedOption?.color === o.color
                    ? "1px solid #000"
                    : undefined,
                height: "28px",
                width: "28px",
                cursor: "pointer",
              }}
            />
          ))}
        </Stack>
        <Stack
          direction="row"
          justifyContent={isSmallScreen ? "center" : "flex-start"}
        >
          <Box
            sx={{
              width: "600px",
              height: isSmallScreen ? "100%" : "400px",
              borderRadius: "10px",
              border: "1px solid #eee",
              overflow: "hidden",
            }}
          >
            <img
              height="100%"
              width="100%"
              src={selectedOption?.image}
              alt={selectedOption?.description}
            />
          </Box>
          {isMediumScreen && !isSmallScreen ? year : null}
        </Stack>
      </Stack>
      {!isMediumScreen ? year : null}

      <Slider key={selectedCar.id} images={selectedCar.images} />
    </Stack>
  );
};
