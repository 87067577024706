import { Stack } from "@mui/material";
import React, { FC } from "react";
import { useCarSwitchContext } from "../hooks/useCarSwitch";
import { Footer } from "./Footer";
import { SelectedCarContent } from "./SelectedCarContent";

export const PageContent: FC = () => {
  const { selectedCar } = useCarSwitchContext();

  return (
    <Stack

      sx={{
        flex: 1,
        justifyContent: "space-between",
      }}
    >
      {selectedCar && (
        <>
          <SelectedCarContent selectedCar={selectedCar} />
          <Footer description={selectedCar.description} />
        </>
      )}
    </Stack>
  );
};
